import React, { useMemo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

export default function EmployeeTable({
  data,
  rows,
  onShowEnrollForm,
  onShowAdmissionKitForm,
  onShowESocialReceiptForm,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page") ?? 1) - 1,
    pageSize: Number(searchParams.get("page_size") ?? 50),
  });

  const handlePaginationModelChange = paginationModel => {
    searchParams.set("page", paginationModel.page + 1);
    searchParams.set("page_size", paginationModel.pageSize);

    setSearchParams(searchParams);
    setPaginationModel(paginationModel);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Matricula",
        field: "RA_MAT",
        flex: 1,
      },
      {
        headerName: "Nome",
        field: "RA_NOMECMP",
        flex: 1,
        renderCell: params => {
          const active = params.row.RA_DEMISSA.trim() ? false : true;

          return `${active ? "🟢" : "🔴"} ${params.row.RA_NOMECMP}`;
        },
      },
      {
        headerName: "Gestor",
        field: "RA_XMATGES",
        flex: 1,
      },
      {
        headerName: "Cargo",
        field: "RJ_DESC",
        flex: 1,
      },
      {
        headerName: "Centro de custo",
        field: "CTT_DESC01",
        flex: 1,
      },
      {
        headerName: "Admissão",
        field: "RA_ADMISSA",
        flex: 1,
        renderCell: params => {
          return moment(params.row.RA_ADMISSA, "YYYYMMDD").format("DD/MM/YYYY");
        },
      },
      {
        headerName: "Demissão",
        field: "RA_DEMISSA",
        flex: 1,
        renderCell: params => {
          return params.row.RA_DEMISSA
            ? moment(params.row.RA_DEMISSA, "YYYYMMDD").format("DD/MM/YYYY")
            : "";
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        width: 70,
        getActions: params => [
          <GridActionsCellItem
            onClick={() => onShowEnrollForm(params.row.RA_MAT)}
            label="Exibir ficha de registro"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              let date = moment(params.row.RA_ADMISSA, "YYYYMMDD").subtract('-60 days').format(
                "YYYY-MM-DD 00:00:00",
              );

              onShowESocialReceiptForm(params.row.RA_MAT, date);
            }}
            label="Exibir recibo eSocial"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => onShowAdmissionKitForm(params.row.RA_MAT)}
            label="Gerar contrato de trabalho"
            showInMenu
          />,
        ],
      },
    ],
    [onShowEnrollForm, onShowESocialReceiptForm, onShowAdmissionKitForm],
  );

  return (
    <DataGrid
      getRowId={row => row.RA_MAT}
      rows={data}
      columns={columns}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      rowCount={rows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
}

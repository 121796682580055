import axios from "axios";

import { TOKEN_NAME } from "../contexts/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;
const BPM_URL = `${API_URL}/api`;

const vmInstance = axios.create();

vmInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(TOKEN_NAME);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

vmInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/logout";
    }

    return Promise.reject(error);
  },
);

export function login(email, password) {
  return vmInstance.post(`${BPM_URL}/auth/login`, {
    email,
    password,
    token_name: "portal-web",
  });
}

export function getMe() {
  return vmInstance.get(`${BPM_URL}/personal-info`);
}

export function getUsers({ query, pageIndex, pageSize, onlyActive }) {
  return vmInstance.get(`${BPM_URL}/users`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
      onlyActive: onlyActive,
    },
  });
}

export function getUser(id) {
  return vmInstance.get(`${BPM_URL}/users/${id}`);
}

export function storeUser({
  name,
  email,
  password,
  active,
  notificate,
  groups,
  papers,
  customer,
  companies,
}) {
  return vmInstance.post(`${BPM_URL}/users`, {
    name,
    email,
    password,
    active,
    notificate,
    groups,
    papers,
    customer,
    companies,
  });
}

export function updateUser(
  id,
  {
    name,
    email,
    password,
    active,
    notificate,
    groups,
    papers,
    customerId,
    companies,
  },
) {
  return vmInstance.put(`${BPM_URL}/users/${id}`, {
    name,
    email,
    password,
    active,
    notificate,
    groups,
    papers,
    customerId,
    companies,
  });
}

export function getGroups({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/groups`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getGroup(id) {
  return vmInstance.get(`${BPM_URL}/groups/${id}`);
}

export function storeGroup({ name, description }) {
  return vmInstance.post(`${BPM_URL}/groups`, {
    name,
    description,
  });
}

export function updateGroup(id, { name, description }) {
  return vmInstance.put(`${BPM_URL}/groups/${id}`, {
    name,
    description,
  });
}

export function getPapers({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/papers`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getPaper(id) {
  return vmInstance.get(`${BPM_URL}/papers/${id}`);
}

export function storePaper({ name, description, permissions }) {
  return vmInstance.post(`${BPM_URL}/papers`, {
    name,
    description,
    permissions,
  });
}

export function updatePaper(id, { name, description, permissions }) {
  return vmInstance.put(`${BPM_URL}/papers/${id}`, {
    name,
    description,
    permissions,
  });
}

export function getPermissions({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/permissions`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getFlows({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/flows`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getFlow(code) {
  return vmInstance.get(`${BPM_URL}/flows/${code}`);
}

export function storeFlow(code, description, diagram) {
  return vmInstance.post(`${BPM_URL}/flows`, {
    code,
    description,
    diagram,
  });
}

export function updateFlow(code, { description, diagram }) {
  return vmInstance.put(`${BPM_URL}/flows/${code}`, {
    description,
    diagram,
  });
}

export function getForms({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/forms`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getForm(code) {
  return vmInstance.get(`${BPM_URL}/forms/${code}`);
}

export function storeForm({ code, description }) {
  return vmInstance.post(`${BPM_URL}/forms`, {
    code,
    description,
  });
}

export function updateForm(code, { description, updateVersion }) {
  return vmInstance.put(`${BPM_URL}/forms/${code}`, {
    description,
    updateVersion,
  });
}

export function getProcesses({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/processes`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getProcess(code) {
  return vmInstance.get(`${BPM_URL}/processes/${code}`);
}

export function getProcessTree(filter) {
  return vmInstance.get(`${BPM_URL}/processes/tree-path?query=${filter}`);
}

export function getFavoriteProcesses() {
  return vmInstance.get(`${BPM_URL}/processes/favorites`);
}

export function favoriteProcess(code, remove) {
  return vmInstance.post(`${BPM_URL}/processes/favorites`, {
    code,
    remove: remove || false,
  });
}

export function storeProcess({
  code,
  description,
  formCode,
  flowCode,
  path,
  unblocked,
}) {
  return vmInstance.post(`${BPM_URL}/processes`, {
    code,
    description,
    formCode,
    flowCode,
    path,
    unblocked,
  });
}

export function updateProcess(
  code,
  { description, formCode, flowCode, path, unblocked, updateVersion },
) {
  return vmInstance.put(`${BPM_URL}/processes/${code}`, {
    description,
    formCode,
    flowCode,
    path,
    unblocked,
    updateVersion,
  });
}

export function getTasks({
  query,
  pageIndex,
  pageSize,
  domain,
  processCode,
  minDate,
  maxDate,
  minId,
  maxId,
  status,
  replacedUserId,
  short,
  participated,
  orderBy,
}) {
  return vmInstance.get(`${BPM_URL}/tasks`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
      domain,
      processCode,
      minDate,
      maxDate,
      minId,
      maxId,
      status,
      replacedUserId,
      short: short || 1,
      participated: participated || 0,
      orderBy,
    },
  });
}

export function getTask(id) {
  return vmInstance.get(`${BPM_URL}/tasks/${id}`);
}

export function getDocuments({ parentId, query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/documents`, {
    params: {
      parentId,
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getDocument(id) {
  return vmInstance.get(`${BPM_URL}/documents/${id}`);
}

export function storeDocument(relativePath, file, parentId) {
  const formData = new FormData();
  formData.append("relativePath", relativePath);
  formData.append("parentId", parentId);
  formData.append("type", "file");
  formData.append("file", file);

  return vmInstance.post(`${BPM_URL}/documents`, formData);
}

export function updateDocument(
  id,
  { name, description, inheritPermission, permissions, safeCode },
) {
  return vmInstance.put(`${BPM_URL}/documents/${id}`, {
    name,
    description,
    inheritPermission,
    permissions,
    safeCode,
  });
}

export function storeFolder({ relativePath, parentId, template }) {
  return vmInstance.post(`${BPM_URL}/documents`, {
    relativePath,
    type: "dir",
    parentId,
    template,
  });
}

export function moveDocument(targetFolderId, documentIds) {
  return vmInstance.put(`${BPM_URL}/documents/move`, {
    targetFolderId,
    documentIds,
  });
}

export function moveDocumentToTrash(documentIds) {
  return vmInstance.put(`${BPM_URL}/documents/move-to-trash`, {
    documentIds,
  });
}

export function downloadDocuments({ documentIds, sync }) {
  return vmInstance.get(`${BPM_URL}/documents/download`, {
    responseType: sync ? "blob" : "json",
    params: {
      documentIds,
    },
  });
}

export function storeTaskAttachment(file) {
  const formData = new FormData();
  formData.append("file", file);

  return vmInstance.post(`${BPM_URL}/tasks/attachment`, formData);
}

export function downloadTaskAttachment(fileStoredId) {
  return vmInstance.get(
    `${BPM_URL}/tasks/attachment/download/${fileStoredId}`,
    {
      responseType: "blob",
    },
  );
}

export function deleteTaskAttachment(fileStoredId) {
  return vmInstance.delete(`${BPM_URL}/tasks/attachment/${fileStoredId}`);
}

export function sendProcessInstance({
  processCode,
  choosedSequence,
  choosedUserId,
  choosedGroupId,
  formData,
  taskId,
  replacedUserId,
  attachments,
  newHistory,
  rating,
}) {
  return vmInstance.post(`${BPM_URL}/process-instances/send`, {
    processCode,
    taskId,
    replacedUserId,
    choosedSequence,
    choosedUserId,
    choosedGroupId,
    formData,
    attachments,
    newHistory,
    rating,
  });
}

export function takeProcessInstance({ taskId, replacedUserId }) {
  return vmInstance.post(`${BPM_URL}/process-instances/take`, {
    taskId,
    replacedUserId,
  });
}

export function transferProcessInstance({ taskId, toId }) {
  return vmInstance.post(`${BPM_URL}/process-instances/transfer`, {
    taskId,
    toId,
  });
}

export function cancelProcessInstance({ taskId }) {
  return vmInstance.post(`${BPM_URL}/process-instances/cancel`, {
    taskId,
  });
}

export function getNextElements({ flowCode, currentElementId, formData }) {
  return vmInstance.post(`${BPM_URL}/flows/${flowCode}/next-elements`, {
    currentElementId,
    formData,
  });
}

export function getReplacedByMe() {
  return vmInstance.get(`${BPM_URL}/substitute-user/replaced-by-me`);
}

export function changePassword({ password, newPassword }) {
  return vmInstance.post(`${BPM_URL}/users/change-password`, {
    password,
    newPassword,
  });
}

export function getCustomers({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/customers`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getCustomer(id) {
  return vmInstance.get(`${BPM_URL}/customers/${id}`);
}

export function storeCustomer({ code, name, active }) {
  return vmInstance.post(`${BPM_URL}/customers`, {
    name,
    code,
    active,
  });
}

export function updateCustomer(id, { code, name, active }) {
  return vmInstance.put(`${BPM_URL}/customers/${id}`, {
    name,
    code,
    active,
  });
}

export function getCalendars({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/calendars`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getCalendar(id) {
  return vmInstance.get(`${BPM_URL}/calendars/${id}`);
}

export function activeCalendar(code) {
  return vmInstance.get(`${BPM_URL}/calendars/active?code=${code}`);
}

export function storeCalendar({
  customerId,
  name,
  status,
  startDate,
  endDate,
  events,
  companies,
}) {
  return vmInstance.post(`${BPM_URL}/calendars`, {
    customerId,
    name,
    status,
    startDate,
    endDate,
    events,
    companies,
  });
}

export function duplicateCalendar(id) {
  return vmInstance.post(`${BPM_URL}/calendars/${id}/duplicate`);
}

export function updateCalendar(
  id,
  { customerId, name, status, startDate, endDate, events, companies },
) {
  return vmInstance.put(`${BPM_URL}/calendars/${id}`, {
    customerId,
    name,
    status,
    startDate,
    endDate,
    events,
    companies,
  });
}

export function getEvents({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/events`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getEvent(id) {
  return vmInstance.get(`${BPM_URL}/events/${id}`);
}

export function storeEvent({
  name,
  type,
  responsible,
  allowAllProcesses,
  processesToAllow,
  denyAllProcesses,
  processesToDeny,
}) {
  return vmInstance.post(`${BPM_URL}/events`, {
    name,
    type,
    responsible,
    allowAllProcesses,
    processesToAllow,
    denyAllProcesses,
    processesToDeny,
  });
}

export function updateEvent(
  id,
  {
    name,
    type,
    responsible,
    allowAllProcesses,
    processesToAllow,
    denyAllProcesses,
    processesToDeny,
  },
) {
  return vmInstance.put(`${BPM_URL}/events/${id}`, {
    name,
    type,
    responsible,
    allowAllProcesses,
    processesToAllow,
    denyAllProcesses,
    processesToDeny,
  });
}

export function getHolidays({ query, pageIndex, pageSize, showOnCalendars }) {
  return vmInstance.get(`${BPM_URL}/holidays`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
      showOnCalendars: showOnCalendars ?? false,
    },
  });
}

export function getHoliday(id) {
  return vmInstance.get(`${BPM_URL}/holidays/${id}`);
}

export function storeHoliday({ name, date, recurrent, showOnCalendars }) {
  return vmInstance.post(`${BPM_URL}/holidays`, {
    name,
    date,
    recurrent,
    showOnCalendars,
  });
}

export function updateHoliday(id, { name, date, recurrent, showOnCalendars }) {
  return vmInstance.put(`${BPM_URL}/holidays/${id}`, {
    name,
    date,
    recurrent,
    showOnCalendars,
  });
}

export function getCompanies({
  query,
  pageIndex,
  pageSize,
  customerId,
  agrupar,
}) {
  return vmInstance.get(`${BPM_URL}/companies`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
      customerId: customerId,
      agrupar: agrupar,
    },
  });
}

export function getCompany(id) {
  return vmInstance.get(`${BPM_URL}/companies/${id}`);
}

export function storeCompany({
  name,
  code,
  active,
  customerId,
  cnpj,
  address,
  city,
  state,
  uf,
  neighborhood,
  zipCode,
  kitDescription,
  email,
  complement,
  stateRegistration,
  logo,
  cnae,
  fpas,
  cityCode,
  admissionFolder,
  absenceFolder,
  cadastralChangeFolder,
  benefitsFolder,
}) {
  return vmInstance.post(`${BPM_URL}/companies`, {
    name,
    code,
    active,
    customerId,
    cnpj,
    address,
    city,
    state,
    uf,
    neighborhood,
    zipCode,
    kitDescription,
    email,
    complement,
    stateRegistration,
    logo,
    cnae,
    fpas,
    cityCode,
    admissionFolder,
    absenceFolder,
    cadastralChangeFolder,
    benefitsFolder,
  });
}

export function updateCompany(
  id,
  {
    name,
    code,
    active,
    customerId,
    cnpj,
    address,
    city,
    state,
    uf,
    neighborhood,
    zipCode,
    kitDescription,
    email,
    complement,
    stateRegistration,
    logo,
    cnae,
    fpas,
    cityCode,
    admissionFolder,
    absenceFolder,
    cadastralChangeFolder,
    benefitsFolder,
  },
) {
  return vmInstance.put(`${BPM_URL}/companies/${id}`, {
    name,
    code,
    active,
    customerId,
    cnpj,
    address,
    city,
    state,
    uf,
    neighborhood,
    zipCode,
    kitDescription,
    email,
    complement,
    stateRegistration,
    logo,
    cnae,
    fpas,
    cityCode,
    admissionFolder,
    absenceFolder,
    cadastralChangeFolder,
    benefitsFolder,
  });
}

export function getSubstitutes({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/substitute-users`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getSubstitute(id) {
  return vmInstance.get(`${BPM_URL}/substitute-users/${id}`);
}

export function storeSubstitute({
  replacedUserId,
  substituteUserId,
  startDate,
  endDate,
  canceled,
}) {
  return vmInstance.post(`${BPM_URL}/substitute-users`, {
    replacedUserId,
    substituteUserId,
    startDate,
    endDate,
    canceled,
  });
}

export function updateSubstitute(
  id,
  { replacedUserId, substituteUserId, startDate, endDate, canceled },
) {
  return vmInstance.put(`${BPM_URL}/substitute-users/${id}`, {
    replacedUserId,
    substituteUserId,
    startDate,
    endDate,
    canceled,
  });
}

export function getReports({ query, pageIndex, pageSize }) {
  return vmInstance.get(`${BPM_URL}/reports`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
    },
  });
}

export function getReport(id) {
  return vmInstance.get(`${BPM_URL}/reports/${id}`);
}

export function storeReport({
  name,
  description,
  type,
  procedure,
  allowMultipleCompanies,
  groups,
}) {
  return vmInstance.post(`${BPM_URL}/reports`, {
    name,
    description,
    type,
    procedure,
    allowMultipleCompanies,
    groups,
  });
}

export function updateReport(
  id,
  { name, description, type, procedure, allowMultipleCompanies, groups },
) {
  return vmInstance.put(`${BPM_URL}/reports/${id}`, {
    name,
    description,
    type,
    procedure,
    allowMultipleCompanies,
    groups,
  });
}

export function getHelpdeskUrl() {
  return vmInstance.post(`${BPM_URL}/service/call`, {
    name: "helpdesk",
    data: {
      origin: "vm",
    },
  });
}

export function getAvaiableChat() {
  return vmInstance.post(`${BPM_URL}/service/call`, {
    name: "chat",
    data: {
      origin: "vm",
    },
  });
}

export function getStatistics({ from, to }) {
  return vmInstance.get(`${BPM_URL}/statistics`, {
    params: {
      from,
      to,
    },
  });
}

export function getProcessInstances({
  query,
  pageIndex,
  pageSize,
  taskId,
  short,
}) {
  return vmInstance.get(`${BPM_URL}/process-instances`, {
    params: {
      query,
      page: pageIndex || 1,
      pageSize: pageSize || 15,
      taskId,
      short: short || 1,
    },
  });
}

export function getProcessInstance(id) {
  return vmInstance.get(`${BPM_URL}/process-instances/${id}`);
}

export function updateProcessInstance(id, { formData }) {
  return vmInstance.put(`${BPM_URL}/process-instances/${id}`, {
    formData,
  });
}
